<template>
	<div>
		<Header title="鲸落三国"></Header>
		<Main>
			<ul>
				<AppList v-for="(i, j) in appdata" :key="j" :data="i"></AppList>
			</ul>
		</Main>
	</div>
</template>

<script>
	import Header from "@/components/common/Header";
	import Main from "@/components/common/Main";
	import AppList from "@/components/common/AppList";
	export default {
		name: "Home",
		components: {
			Header,
			Main,
			AppList
		},
		data() {
			return {
				appdata: [
					{
						title: "鲸落三国3.0",
						info: "冒泡幻想三国单开辅助",
						img: "dankai.png",
						download: "https://geqian.lanzous.com/b015v6g2h"
					}
				]
			}
		}
	}
</script>

<style scoped>
	ul {
		margin: 1rem;
	}
</style>
