<template>
	<li>
		<div>
			<img :src="data.img" alt="123">
			<div>
				<h2>{{ data.title }}</h2>
				<p>{{ data.info }}</p>
			</div>
			<span>
				<a :href="data.download">下载</a>
			</span>
		</div>
	</li>
</template>

<script>
	export default {
		name: "AppList",
		props: {
			data: Object
		}
	}
</script>

<style scoped lang="less">
	li{
		margin-top: 1rem;
		>div {
			display: flex;
			height: 4rem;
			background-color: #c8c8c8;
			border-radius: 1.2rem;
			>img {
				width: 4rem;
				height: 4rem;
				padding: .5rem;
			}
			>div {
				width: 10rem;
				padding: .5rem 0;
				>h2 {
					font-size: .9rem;
					height: 1.5rem;
				}
				>p {
					height: 1.5rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-size: .6rem;
					color: #666;
					line-height: 1.5rem;
				}
			}
			>span {
				width: 4rem;
				padding: .5rem;
				>a {
					display: block;
					height: 1.4rem;
					background-color: #fff;
					border-radius: .7rem;
					text-align: center;
					font-weight: 700;
					font-size: .8rem;
					line-height: 1.4rem;
				}
			}
		}
	}
</style>
